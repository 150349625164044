export default {

    userId: "474903993362022420",
    github: "SpanyDev",
    description: "My name is Spany. I am a 16 year old Turkish student. I am interested in software. I am a bot developer on the Discord platform.",
    about: {
        description: "Hello. My name is <span class='text-primary'>Spany</span>. I am <span class='text-primary'>16 years</span> old. I am a <span class='text-primary'>Turkish student.</span> I am interested in <span class='text-primary'>Software</span>. I am a <span class='text-primary'>bot developer on the</span> Discord platform.",
        liveIn: "İstanbul, Bağcılar",
        birthday: "16 October, 2007",
        gender: "Man"
    }

}
