export default {

    active: [

        {
            name: "Axium Bot",
            description: "A bot that has been presented with advanced systems and features on the Discord platform and is actively serving.",
            image: "https://media.discordapp.net/attachments/1051561736672641145/1051561866058534982/AxiumBanner.png?width=793&height=134",
            role: "Owner and Developer",
            link: "https://discord.gg/mnP93bP3"
        }
        
    ]

}
